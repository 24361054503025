import { OrderDetails, OrderItem } from '../orders.model';
import { QuestionDetails } from '../questions.model';
import { Offer, RfqDeposit, RfqDetails } from '../rfq.model';
import { ChatMessageBase, Thread } from '../threads.model';
import { ApiResultList, SearchRequestBase } from './common.model';

export interface OrdersRequest extends SearchRequestBase {}
export type OrdersResult = ApiResultList<OrderItem[]>;

export type OrderRequest = {};
export type OrderResult = { data: OrderDetails };

export type ThreadsRequest = {};
export type ThreadsResult = { data: Thread };

export type ThreadsMessageRequest = {};
export type ThreadsMessageResult = { data: ChatMessageBase };

export type RfqUserRequest = {};
export type RfqUserResult = { data: RfqDetails };

export type OfferRequest = {};
export type OfferResult = { data: Offer };

export type RfqDepositRequest = {};
export type RfqDepositResult = { data: RfqDeposit };

export type QuestionRequest = {};
export type QuestionResult = { data: QuestionDetails };

export type QuestionHidePhoneRequest = { sharePhone: boolean };
export type QuestionHidePhoneResult = { data: { sharePhone: boolean } };

export interface ReactionChatRequest {
  reaction: number;
}

export enum ClientTotalSections {
  Orders = 'order',
  Queries = 'query',
  Rfq = 'rfq',
  Total = 'total'
}

export type ClientTotalEntities = Record<ClientTotalSections, number>;

export type ClientTotalEntitiesRequest = {};
export type ClientTotalEntitiesResult = {
  data: ClientTotalEntities;
};
