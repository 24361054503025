import { MenuItem } from '@app/shared/components/menu-item/menu-item.types';
import { Sections, StatusType } from '@app/shared/types';
import { ClientTotalEntities, ClientTotalSections } from '@shared/models/api';

export const ORDERS_STATUSES: Record<number, StatusType> = {
  0: StatusType.Neutral,
  3: StatusType.Success,
  44: StatusType.Neutral,
  107: StatusType.Neutral,
  108: StatusType.Neutral,
  109: StatusType.Neutral,
  110: StatusType.Neutral,
  113: StatusType.Error,
  115: StatusType.Neutral,
  112: StatusType.Error,
  119: StatusType.Success,
  120: StatusType.Neutral,
  123: StatusType.Neutral
};

export const RFQS_STATUSES: Record<number, StatusType> = {
  3: StatusType.Success,
  41: StatusType.Neutral,
  44: StatusType.Neutral,
  108: StatusType.Error,
  109: StatusType.Error,
  110: StatusType.Neutral,
  113: StatusType.Error,
  170: StatusType.Success,
  172: StatusType.Neutral
};

export const unreadItems = (): ClientTotalEntities => ({
  [ClientTotalSections.Orders]: 0,
  [ClientTotalSections.Rfq]: 0,
  [ClientTotalSections.Queries]: 0,
  [ClientTotalSections.Total]: 0
});

export const menuItems = (context: any): MenuItem[] => [
  menuItemsActivity(context),
  {
    name: context.isClient ? null : '*'
  },
  {
    name: 'ordersPage.menu.profile',
    link: '/client/profile',
    icon: 'user'
  },
  {
    name: 'ordersPage.menu.logout',
    icon: 'logout',
    action: () => {
      context.userService.logout();
      context.router.navigate([context.localize.translateRoute('/')]);
    }
  }
];

export const menuItemsActivity = (context: any): MenuItem => {
  return !context.authService.isAuthorized || !context.isClient
    ? { name: '*' }
    : {
        name: 'ordersPage.menu.activity',
        icon: 'pulse',
        count: context.getTotalUnreadItems(),
        items: [
          { name: 'ordersPage.menu.questions', link: '/client/questions', icon: 'chat' },
          {
            name: 'ordersPage.menu.orders',
            link: '/client/orders',
            icon: 'stack',
            count: context.getCountUnreadItems(Sections.Orders)
          },
          {
            name: 'ordersPage.menu.rfq',
            link: '/client/rfq',
            icon: 'hire',
            count: context.getCountUnreadItems(Sections.Rfq)
          }
        ]
      };
};

export const mapSectionsTotal = {
  [Sections.Orders]: ClientTotalSections.Orders,
  [Sections.Rfq]: ClientTotalSections.Rfq,
  [Sections.QuestionsUser]: ClientTotalSections.Queries
};
